<template>
<div class="user-card">
    <div class="left">
        <img v-if="currentJobType == 3" class="user-img" :src="avatarUrl" alt="">
        <avatar
            v-else
            class="user-avatar"
            size="mg"
            :src="avatarUrl"
            :userId="userId"
            :enableCard="true"
            :enableLink="true"
            :shareData="shareData"
        ></avatar>
    </div>
    <div class="right">
        <div class="user-info">
            <p class="user-name" :title="realName + '@' + nickName">
                <span
                    class="user-online"
                    :class="isOnline ? 'is-online' : ''"
                ></span>
                {{realName}}
                <span class="text-grey">@{{nickName}}</span>
            </p>
            <div
                class="pm-icon"
                v-if="isPM"
            >
                PM
            </div>
            <p class="user-detail" :title="firmName + title">
                {{firmName}} {{title}}
            </p>
        </div>
        <slot></slot>
    </div>
</div>
</template>

<script>
import Avatar from '#/component/common/avatar.vue';
export default {
    name: "userCard",
    components: {
        Avatar
    },
    props: {
        currentJobType: Number,
        avatarUrl: String,
        userId: String,
        userUnionId: {
            type: String,
            default: ""
        },
        isOnline: {
            type: Boolean,
            default: false
        },
        realName: String,
        nickName: String,
        firmName: String,
        title: String,
        isPM: {
            type: Boolean,
            default: false
        },
        shareData: Object
    }
}
</script>

<style lang="scss" scope>
    .user-card {
        min-height: 132px;
        padding: 25px 20px 10px;
        background: #f6f6f6;
        display: flex;
        .text-grey {
            color: #b9b9b9;
        }
        .left {
            margin-right: 12px;
            .user-img {
                width: 58px;
                height: 58px;
                border-radius: 50%;
            }
        }
        .right {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            line-height: 24px;
            p {
                margin: 0;
                word-wrap: break-word;
                word-break: break-all;
            }
            .user-online {
                float: left;
                width: 8px;
                height: 8px;
                margin-top: 9px;
                background-color: #b9b9b9;
                border-radius: 50%;
                margin-right: 5px;
                cursor: default;
                &.is-online {
                    background-color: $primary;
                }
            }
            .user-name, .user-detail {
                // white-space: pre-wrap;
                width: 202px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .pm-icon {
                width: 36px;
                height: 16px;
                margin-bottom: 8px;
                text-align: center;
                line-height: 16px;
                background: $primary;
                color: #fff;
                font-size: 12px;
            }
        }
    }
</style>