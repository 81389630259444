var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-card" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _vm.currentJobType == 3
          ? _c("img", {
              staticClass: "user-img",
              attrs: { src: _vm.avatarUrl, alt: "" },
            })
          : _c("avatar", {
              staticClass: "user-avatar",
              attrs: {
                size: "mg",
                src: _vm.avatarUrl,
                userId: _vm.userId,
                enableCard: true,
                enableLink: true,
                shareData: _vm.shareData,
              },
            }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("div", { staticClass: "user-info" }, [
          _c(
            "p",
            {
              staticClass: "user-name",
              attrs: { title: _vm.realName + "@" + _vm.nickName },
            },
            [
              _c("span", {
                staticClass: "user-online",
                class: _vm.isOnline ? "is-online" : "",
              }),
              _vm._v(
                "\r\n                " +
                  _vm._s(_vm.realName) +
                  "\r\n                "
              ),
              _c("span", { staticClass: "text-grey" }, [
                _vm._v("@" + _vm._s(_vm.nickName)),
              ]),
            ]
          ),
          _vm.isPM
            ? _c("div", { staticClass: "pm-icon" }, [
                _vm._v("\r\n                PM\r\n            "),
              ])
            : _vm._e(),
          _c(
            "p",
            {
              staticClass: "user-detail",
              attrs: { title: _vm.firmName + _vm.title },
            },
            [
              _vm._v(
                "\r\n                " +
                  _vm._s(_vm.firmName) +
                  " " +
                  _vm._s(_vm.title) +
                  "\r\n            "
              ),
            ]
          ),
        ]),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }